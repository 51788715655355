<template>
    <div class="container-fluid">
        <section class="content-header">
                <div class="row mb-2">
                    <h3>{{ $t('general.title') }}</h3>
                </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-6">
                    <configuracion-empresa :factoadmin="factoadmin"/>
                    <configuracion-facturacion :factoadmin="factoadmin"/>
                    <configuracion-comunicaciones v-if="factoadmin"/>
                </div>
                <div class="col-md-6">
                    <configuracion-administracion :factoadmin="factoadmin"/>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

//Iconos en https://fontawesome.com/v5/search?m=free&s=solid%2Cbrands
import ConfiguracionEmpresa from './ConfiguracionEmpresa.vue'
import ConfiguracionAdministracion from './ConfiguracionAdministracion.vue';
import ConfiguracionFacturacion from './ConfiguracionFacturacion.vue';
import ConfiguracionComunicaciones from './ConfiguracionComunicaciones.vue';



//import Dialog from 'primevue/dialog';

import { PwgsApi } from '../../../services/PwgsApi';
export default {
    components: {
    "configuracion-empresa": ConfiguracionEmpresa,
    "configuracion-administracion": ConfiguracionAdministracion,
    "configuracion-facturacion": ConfiguracionFacturacion,
    "configuracion-comunicaciones": ConfiguracionComunicaciones,
    
},
    data() {
        return {
            factoadmin:false,
        }
    },
    methods:{
        async comprobarpermisos(){
            const api = new PwgsApi();
            let auxusuario = localStorage.getItem('usuario');
            const resppermisos = await api.get('usuarios/'+auxusuario+'/permisos');
            var permisos = resppermisos.datos;
            const factencontrado = permisos.find(permiso => permiso.nombre_permiso == "Facturación");
            const adminencontrado= permisos.find(permiso => permiso.nombre_permiso == "Administración");
            console.log('gggs',factencontrado, adminencontrado);
            if(factencontrado || adminencontrado){
                console.log('entra');
                this.factoadmin = true;
            }
        }
    },
    mounted(){
        this.comprobarpermisos();
    }
}
</script>